//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         updateFvapLeosWithButter } from '~/utils/butterUtils'
import { PDFDocument,
         StandardFonts,
         PageSizes,
         rgb } from 'pdf-lib'
import download from 'downloadjs'
import axios from 'axios'

export default {
  name: 'PDF-State-Rules',
  data () {
    return {
      svgPath: [],
      svgDot: [],
      isActive: false,
    }
  },
  props: [ 'butterLeos',
           'butterStateRules',
           'dict',
           'statesElections',
           'stateRules',
           'stateRulesDescriptions',
           'parameters' ],
  methods: {
    setupLogoSVG() {
      // Absentee Ballots for U.S. Citizens

      // Absentee
      this.svgPath.push("M147.1,75h-2.1l-0.8-2.6h-3.8l-0.8,2.6h-2l3.7-11.5h2L147.1,75L147.1,75z M143.6,70.5l-1.3-4.2l-1.3,4.2H143.6z")
      this.svgPath.push("M151.4,75V63.6h1.8v4.1c0.3-0.4,0.6-0.7,0.9-0.9c0.3-0.2,0.7-0.3,1.1-0.3c0.8,0,1.5,0.4,2,1.1c0.6,0.7,0.8,1.8,0.8,3.2s-0.3,2.5-0.9,3.2s-1.2,1.1-2,1.1c-0.4,0-0.8-0.1-1.1-0.4c-0.4-0.2-0.7-0.6-1-1.1V75L151.4,75L151.4,75zM153.2,70.7c0,0.7,0.1,1.3,0.2,1.6c0.1,0.4,0.3,0.7,0.6,0.9s0.5,0.3,0.8,0.3c0.4,0,0.7-0.2,1-0.6c0.3-0.4,0.4-1.1,0.4-2c0-0.9-0.1-1.6-0.4-2.1c-0.3-0.4-0.6-0.6-1.1-0.6s-0.8,0.2-1.1,0.6S153.2,69.9,153.2,70.7z")
      this.svgPath.push("M162.4,72.7l1.8-0.3c0.2,0.9,0.6,1.3,1.4,1.3c0.4,0,0.8-0.1,1-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.6-0.3c-1.5-0.4-2.4-0.8-2.8-1.1c-0.5-0.4-0.8-1.1-0.8-1.8s0.2-1.4,0.7-1.9c0.5-0.5,1.2-0.7,2.1-0.7s1.6,0.2,2,0.5c0.5,0.3,0.8,0.9,1,1.7l-1.7,0.4c-0.1-0.7-0.6-1-1.3-1c-0.4,0-0.8,0.1-0.9,0.2s-0.3,0.3-0.3,0.5c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.7,0.3,1.6,0.6c1,0.3,1.7,0.6,2.1,1c0.4,0.4,0.5,0.9,0.5,1.6c0,0.8-0.3,1.5-0.8,2c-0.6,0.5-1.3,0.8-2.3,0.8c-0.9,0-1.6-0.2-2.2-0.7S162.5,73.5,162.4,72.7L162.4,72.7z")
      this.svgPath.push("M177.7,72.4l1.8,0.4c-0.2,0.8-0.6,1.4-1.1,1.9c-0.5,0.4-1.1,0.6-1.9,0.6c-1,0-1.8-0.3-2.3-1c-0.6-0.8-1-1.9-1-3.3s0.3-2.5,1-3.3c0.6-0.7,1.3-1.1,2.1-1.1c1,0,1.7,0.4,2.3,1.1c0.6,0.8,0.9,2,0.9,3.7v0.2H175c0,0.7,0.2,1.2,0.4,1.5c0.3,0.4,0.6,0.5,1,0.5C177.1,73.6,177.5,73.2,177.7,72.4L177.7,72.4z M177.8,70.2c0-0.7-0.1-1.1-0.4-1.5c-0.3-0.3-0.6-0.5-0.9-0.5c-0.4,0-0.7,0.2-1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4L177.8,70.2L177.8,70.2z")
      this.svgPath.push("M190.6,75h-1.8v-4.2c0-0.8,0-1.4-0.1-1.7s-0.2-0.5-0.4-0.7s-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.5-0.5,0.8s-0.1,1-0.1,1.9V75h-1.8v-8.3h1.7v1.2c0.3-0.5,0.6-0.8,1-1.1s0.8-0.3,1.2-0.3c0.5,0,1,0.1,1.3,0.4c0.4,0.3,0.6,0.6,0.8,1.1s0.2,1.1,0.2,1.9L190.6,75L190.6,75z")
      this.svgPath.push("M199,66.7v1.8h-1.2v3.3c0,0.7,0,1.2,0.1,1.3c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.7-0.2l0.2,1.7c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.3-0.1-0.9-0.1-1.8v-3.6h-0.8v-1.8h0.8v-1.7l1.8-1.3v2.9L199,66.7L199,66.7z")
      this.svgPath.push("M207.7,72.4l1.8,0.4c-0.2,0.8-0.6,1.4-1.1,1.9c-0.5,0.4-1.1,0.6-1.9,0.6c-1,0-1.8-0.3-2.3-1c-0.6-0.8-1-1.9-1-3.3s0.3-2.5,1-3.3c0.6-0.7,1.3-1.1,2.1-1.1c1,0,1.7,0.4,2.3,1.1c0.6,0.8,0.9,2,0.9,3.7v0.2H205c0,0.7,0.2,1.2,0.4,1.5c0.3,0.4,0.6,0.5,1,0.5C207.1,73.6,207.5,73.2,207.7,72.4L207.7,72.4z M207.8,70.2c0-0.7-0.1-1.1-0.4-1.5c-0.3-0.3-0.6-0.5-0.9-0.5c-0.4,0-0.7,0.2-1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4L207.8,70.2L207.8,70.2z")
      this.svgPath.push("M218.4,72.4l1.8,0.4c-0.2,0.8-0.6,1.4-1.1,1.9c-0.5,0.4-1.1,0.6-1.9,0.6c-1,0-1.8-0.3-2.3-1c-0.6-0.8-1-1.9-1-3.3s0.3-2.5,1-3.3c0.6-0.7,1.3-1.1,2.1-1.1c1,0,1.7,0.4,2.3,1.1c0.6,0.8,0.9,2,0.9,3.7v0.2h-4.5c0,0.7,0.2,1.2,0.4,1.5c0.3,0.4,0.6,0.5,1,0.5C217.8,73.6,218.2,73.2,218.4,72.4L218.4,72.4z M218.5,70.2c0-0.7-0.1-1.1-0.4-1.5c-0.3-0.3-0.6-0.5-0.9-0.5c-0.4,0-0.7,0.2-1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4L218.5,70.2L218.5,70.2z")

      // Ballots
      this.svgPath.push("M232.3,63.6h3.8c1,0,1.8,0.1,2.2,0.3s0.8,0.5,1.1,1s0.4,1,0.4,1.6c0,0.6-0.1,1.1-0.4,1.5s-0.6,0.8-1,1c0.6,0.2,1,0.5,1.3,1c0.3,0.5,0.5,1.1,0.5,1.8c0,0.8-0.2,1.5-0.6,2.1c-0.4,0.6-0.9,0.9-1.4,1.1c-0.4,0.1-1.3,0.1-2.6,0.1h-3.2L232.3,63.6L232.3,63.6z M234.2,65.5v2.7h1.2c0.8,0,1.2,0,1.4,0c0.3-0.1,0.6-0.2,0.8-0.4s0.3-0.5,0.3-0.9c0-0.3-0.1-0.6-0.2-0.8s-0.3-0.3-0.5-0.4c-0.2-0.1-0.8-0.1-1.8-0.1L234.2,65.5L234.2,65.5z M234.2,70.1v3.1h1.8c0.8,0,1.3,0,1.5-0.1s0.4-0.2,0.6-0.5c0.1-0.2,0.2-0.5,0.2-0.9s-0.1-0.7-0.2-0.9s-0.4-0.4-0.6-0.5c-0.3-0.1-0.8-0.1-1.6-0.1L234.2,70.1L234.2,70.1z")
      this.svgPath.push("M246.5,69.3l-1.6-0.4c0.2-0.8,0.5-1.4,1-1.8s1.1-0.6,2-0.6c0.8,0,1.3,0.1,1.7,0.3c0.4,0.2,0.7,0.5,0.9,0.9s0.3,1,0.3,2l0,2.6c0,0.7,0,1.3,0.1,1.6s0.2,0.7,0.3,1.1h-1.8l-0.2-0.9c-0.3,0.4-0.6,0.6-1,0.8s-0.7,0.3-1.1,0.3c-0.7,0-1.2-0.2-1.6-0.7s-0.6-1-0.6-1.8c0-0.5,0.1-0.9,0.3-1.2c0.2-0.3,0.4-0.6,0.7-0.8s0.8-0.4,1.5-0.6c0.8-0.2,1.4-0.4,1.7-0.5c0-0.5,0-0.8-0.1-0.9s-0.2-0.3-0.4-0.3s-0.4-0.1-0.7-0.1s-0.6,0.1-0.8,0.2S246.7,68.8,246.5,69.3L246.5,69.3z M248.9,71.1c-0.2,0.1-0.6,0.2-1.1,0.3c-0.6,0.2-0.9,0.3-1.1,0.5s-0.3,0.4-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.8-0.3c0.2-0.2,0.4-0.4,0.5-0.6s0.1-0.7,0.1-1.3V71.1L248.9,71.1z")
      this.svgPath.push("M255.9,75V63.6h1.8V75H255.9L255.9,75z")
      this.svgPath.push("M263,75V63.6h1.8V75H263z")
      this.svgPath.push("M269.7,70.8c0-0.8,0.2-1.6,0.5-2.2c0.3-0.7,0.8-1.2,1.3-1.5s1.1-0.5,1.8-0.5c1.1,0,2,0.4,2.6,1.3s0.9,1.9,0.9,3c0,0.9-0.2,1.6-0.5,2.3s-0.8,1.2-1.3,1.6s-1.1,0.5-1.7,0.5c-1,0-1.9-0.4-2.5-1.2C270,73.3,269.7,72.2,269.7,70.8L269.7,70.8z M271.5,70.9c0,0.8,0.2,1.5,0.5,1.9s0.7,0.7,1.2,0.7s0.8-0.2,1.2-0.7c0.3-0.4,0.5-1.1,0.5-1.9s-0.2-1.5-0.5-1.9s-0.7-0.7-1.2-0.7s-0.8,0.2-1.2,0.7S271.5,70.1,271.5,70.9L271.5,70.9z")
      this.svgPath.push("M284.7,66.7v1.8h-1.2v3.3c0,0.7,0,1.2,0,1.3c0.1,0.2,0.2,0.3,0.5,0.3c0.2,0,0.4-0.1,0.7-0.2l0.2,1.7c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.5-0.5-0.5-0.9c-0.1-0.3-0.1-0.9-0.1-1.8v-3.6h-0.8v-1.8h0.8v-1.7l1.8-1.3v2.9L284.7,66.7L284.7,66.7z")
      this.svgPath.push("M288.7,72.7l1.8-0.3c0.2,0.9,0.6,1.3,1.5,1.3c0.4,0,0.8-0.1,1-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.6-0.3c-1.5-0.4-2.4-0.8-2.8-1.1c-0.5-0.4-0.8-1.1-0.8-1.8s0.2-1.4,0.7-1.9c0.5-0.5,1.2-0.7,2.1-0.7s1.6,0.2,2,0.5s0.8,0.9,1,1.7l-1.7,0.4c-0.1-0.7-0.6-1-1.3-1c-0.4,0-0.8,0.1-1,0.2s-0.3,0.3-0.3,0.5c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.7,0.3,1.6,0.6c1,0.3,1.7,0.6,2,1c0.4,0.4,0.5,0.9,0.5,1.6c0,0.8-0.3,1.5-0.8,2s-1.3,0.8-2.3,0.8c-0.9,0-1.6-0.2-2.2-0.7S288.9,73.5,288.7,72.7L288.7,72.7z")

      // for
      this.svgPath.push("M306.4,66.7h1v-0.6c0-0.8,0.1-1.3,0.2-1.6c0.1-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1.2-0.3c0.5,0,1,0.1,1.5,0.3l-0.2,1.5c-0.3-0.1-0.6-0.1-0.8-0.1s-0.4,0.1-0.5,0.2s-0.2,0.4-0.2,0.9v0.6h1.3v1.7h-1.3V75h-1.8v-6.6h-1L306.4,66.7L306.4,66.7z")
      this.svgPath.push("M314.6,70.8c0-0.8,0.2-1.6,0.5-2.2c0.3-0.7,0.8-1.2,1.3-1.5s1.1-0.5,1.8-0.5c1.1,0,2,0.4,2.6,1.3s0.9,1.9,0.9,3c0,0.9-0.2,1.6-0.5,2.3s-0.8,1.2-1.3,1.6s-1.1,0.5-1.7,0.5c-1,0-1.9-0.4-2.5-1.2C314.9,73.3,314.6,72.2,314.6,70.8L314.6,70.8z M316.4,70.9c0,0.8,0.2,1.5,0.5,1.9s0.7,0.7,1.2,0.7s0.8-0.2,1.2-0.7c0.3-0.4,0.5-1.1,0.5-1.9s-0.2-1.5-0.5-1.9s-0.7-0.7-1.2-0.7s-0.8,0.2-1.2,0.7S316.4,70.1,316.4,70.9L316.4,70.9z")
      this.svgPath.push("M328.2,75h-1.8v-8.3h1.7v1.2c0.3-0.6,0.5-0.9,0.8-1.1c0.2-0.2,0.5-0.3,0.8-0.3c0.4,0,0.8,0.1,1.2,0.4l-0.6,1.9c-0.3-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.5,0.1-0.7,0.3s-0.3,0.5-0.4,1s-0.1,1.4-0.1,2.7L328.2,75L328.2,75z")

      // U.S.
      this.svgPath.push("M342.1,63.6h1.9v6.2c0,1,0,1.6,0.1,1.9c0.1,0.5,0.3,0.9,0.6,1.2c0.3,0.3,0.7,0.4,1.2,0.4c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.5-0.5,0.6-0.8c0.1-0.3,0.1-1.1,0.1-2.2v-6.3h1.9v6c0,1.5-0.1,2.7-0.2,3.3c-0.2,0.7-0.5,1.2-1.1,1.7c-0.6,0.4-1.3,0.6-2.3,0.6s-1.7-0.2-2.3-0.5s-1-0.8-1.2-1.5c-0.2-0.6-0.4-1.8-0.4-3.5L342.1,63.6L342.1,63.6z")
      this.svgPath.push("M353.4,75v-2.2h1.8V75H353.4z")
      this.svgPath.push("M358.5,71.3l1.9-0.2c0.2,1.5,0.9,2.2,2,2.2c0.6,0,1-0.2,1.4-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.3-0.5-0.4s-0.8-0.3-1.7-0.6c-0.8-0.2-1.4-0.5-1.8-0.8s-0.7-0.7-0.9-1.1s-0.3-1-0.3-1.5c0-0.6,0.1-1.2,0.4-1.7c0.3-0.5,0.7-0.9,1.2-1.1c0.5-0.3,1.1-0.4,1.9-0.4c1.1,0,2,0.3,2.6,0.9c0.6,0.6,1,1.5,1,2.6l-1.9,0.1c-0.1-0.6-0.3-1-0.5-1.3c-0.3-0.2-0.7-0.4-1.2-0.4s-0.9,0.1-1.2,0.3s-0.4,0.5-0.4,0.8s0.1,0.6,0.4,0.8c0.2,0.2,0.8,0.4,1.7,0.7c1,0.3,1.6,0.6,2.1,0.9c0.4,0.3,0.8,0.7,1,1.2s0.3,1.1,0.3,1.8c0,1-0.3,1.8-0.9,2.5c-0.6,0.7-1.6,1-2.9,1C360,75.2,358.8,73.9,358.5,71.3L358.5,71.3z")
      this.svgPath.push("M369.5,75v-2.2h1.8V75H369.5z")

      // Citizens
      this.svgPath.push("M388.1,70.8l1.8,0.7c-0.3,1.3-0.8,2.2-1.4,2.8c-0.7,0.6-1.5,0.9-2.5,0.9c-1.2,0-2.2-0.5-3-1.4c-0.9-1.1-1.3-2.6-1.3-4.4c0-1.9,0.4-3.5,1.3-4.6c0.8-1,1.8-1.4,3.1-1.4c1,0,1.9,0.4,2.6,1.1c0.5,0.5,0.9,1.3,1.1,2.3l-1.9,0.6c-0.1-0.6-0.4-1.1-0.7-1.4c-0.4-0.3-0.8-0.5-1.3-0.5c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.6-0.7,1.6-0.7,3s0.2,2.5,0.6,3.1s1,0.9,1.7,0.9c0.5,0,0.9-0.2,1.3-0.6C387.6,72.3,387.9,71.7,388.1,70.8L388.1,70.8z")
      this.svgPath.push("M395,65.6v-2h1.8v2H395z M395,75v-8.3h1.8V75H395z")
      this.svgPath.push("M405.1,66.7v1.8h-1.2v3.3c0,0.7,0,1.2,0,1.3c0.1,0.2,0.2,0.3,0.5,0.3c0.2,0,0.4-0.1,0.7-0.2l0.2,1.7c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.5-0.5-0.5-0.9c-0.1-0.3-0.1-0.9-0.1-1.8v-3.6h-0.8v-1.8h0.8v-1.7l1.8-1.3v2.9L405.1,66.7L405.1,66.7z")
      this.svgPath.push("M409.9,65.6v-2h1.8v2H409.9z M409.9,75v-8.3h1.8V75H409.9z")
      this.svgPath.push("M416.2,75v-1.7l2.5-3.6c0.4-0.6,0.7-1,0.9-1.2c-0.2,0-0.5,0-0.8,0l-2.4,0v-1.8h5.6v1.6l-2.6,3.7l-0.9,1.2c0.5,0,0.8-0.1,0.9-0.1h2.8v1.9L416.2,75L416.2,75z")
      this.svgPath.push("M430.9,72.4l1.8,0.4c-0.2,0.8-0.6,1.4-1.1,1.9c-0.5,0.4-1.1,0.6-1.9,0.6c-1,0-1.8-0.3-2.3-1c-0.6-0.8-1-1.9-1-3.3s0.3-2.5,1-3.3c0.5-0.7,1.3-1.1,2.1-1.1c1,0,1.7,0.4,2.3,1.1c0.6,0.8,1,2,1,3.7v0.2h-4.5c0,0.7,0.2,1.2,0.4,1.5c0.3,0.4,0.6,0.5,1,0.5C430.3,73.6,430.7,73.2,430.9,72.4L430.9,72.4z M431,70.2c0-0.7-0.1-1.1-0.4-1.5c-0.3-0.3-0.6-0.5-0.9-0.5c-0.4,0-0.7,0.2-1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4L431,70.2L431,70.2z")
      this.svgPath.push("M443.9,75h-1.8v-4.2c0-0.8,0-1.4-0.1-1.7s-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.5-0.5,0.8s-0.1,1-0.1,1.9V75h-1.8v-8.3h1.7v1.2c0.3-0.5,0.6-0.8,1-1.1s0.8-0.3,1.2-0.3c0.5,0,1,0.1,1.3,0.4c0.4,0.3,0.6,0.6,0.8,1.1s0.2,1.1,0.2,1.9V75L443.9,75z")
      this.svgPath.push("M448.5,72.7l1.8-0.3c0.2,0.9,0.6,1.3,1.5,1.3c0.4,0,0.8-0.1,1-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.6-0.3c-1.5-0.4-2.4-0.8-2.8-1.1c-0.5-0.4-0.8-1.1-0.8-1.8s0.2-1.4,0.7-1.9c0.5-0.5,1.2-0.7,2.1-0.7s1.6,0.2,2,0.5s0.8,0.9,1,1.7l-1.7,0.4c-0.1-0.7-0.6-1-1.3-1c-0.4,0-0.8,0.1-1,0.2s-0.3,0.3-0.3,0.5c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.7,0.3,1.6,0.6c1,0.3,1.7,0.6,2,1c0.4,0.4,0.5,0.9,0.5,1.6c0,0.8-0.3,1.5-0.8,2s-1.3,0.8-2.3,0.8c-0.9,0-1.6-0.2-2.2-0.7S448.7,73.5,448.5,72.7L448.5,72.7z")


      // Vote
      this.svgPath.push("M28.5,46.4h-6.9c-3.7-11-7.2-22.4-10.1-33.8c1.9-0.4,4.7-0.6,6.2-0.6l6.8,24.5c0.2,0.8,0.4,1.8,0.5,2.8h0.5c0-1,0.3-1.9,0.5-2.8l6.9-24.5c1.6,0,4.3,0.2,5.9,0.6C35.8,24.2,32.3,35.3,28.5,46.4")
      this.svgPath.push("M52.5,46.9c-6.9,0-11.6-4.2-11.6-13.7c0-8.6,4.9-13.7,11.8-13.7s11.7,3.8,11.7,13.7C64.4,42.2,59.2,46.9,52.5,46.9 M52.7,24.5c-3.4,0-5.5,2.2-5.5,8.8s1.8,8.8,5.4,8.8s5.7-2.2,5.7-8.8S56.2,24.5,52.7,24.5")
      this.svgPath.push("M79.1,46.9c-5.9,0-8.4-2.9-8.4-9.1V25.1h-3.1V20h3.1v-5.6c1.7-0.4,4-0.9,5.8-1V20h6.8c0,1.6-0.1,3.3-0.4,5.1h-6.4v13.2c0,2.7,1,3.3,3.4,3.3c1.3,0,2.9-0.3,3.8-0.8c0.4,1.5,0.7,3.3,0.8,5C83,46.5,80.5,46.9,79.1,46.9")
      this.svgPath.push("M108.4,34.7H93.6v0.5c0,4.3,1.4,6.3,6.4,6.3c2.4,0,5.4-0.7,7-1.6c0.6,1.5,1.1,3.6,1.3,5.4c-2.2,1.1-5.9,1.7-8.4,1.7c-8.1,0-12.6-4.5-12.6-13.4c0-9.5,4.8-14.1,11.3-14.1c6.9,0,10.1,4.4,10.1,11.3C108.6,31.9,108.6,33.5,108.4,34.7 M93.6,30.8h9.2c0.1-0.3,0.1-1.2,0.1-1.5c0-3.7-1.5-5.4-4.4-5.4S93.7,26,93.6,30.8")

      // from
      this.svgPath.push("M134.2,14.1c-0.5-0.1-1.9-0.1-2.6-0.1c-2,0-2.8,0.9-2.8,3.2V20h5.8c0,1.7-0.1,3.4-0.4,5.1h-5.4v22.3c0,5.4-2.8,10-8.5,10c-1,0-2.4-0.2-3-0.2c0-1.7,0.1-3.4,0.3-5.2c0.5,0.1,2,0.2,2.6,0.2c2,0,2.8-1.1,2.8-3.2V25.1h-3V20h3v-2.3c0-5.5,3.7-8.9,8.5-8.9c1,0,2.5,0.1,3.1,0.2C134.6,10.7,134.5,12.4,134.2,14.1")
      this.svgPath.push("M152.8,25.8c-0.6-0.1-1.5-0.2-2.4-0.2c-1.8,0-3.5,0.4-5.3,1.8v19.1h-5.8V20.2c1.6-0.2,3.9-0.4,5.8-0.4v3.4h0.3c2.6-3.4,4.4-3.7,6-3.7c0.5,0,1.3,0.1,1.9,0.3C153.4,21.8,153.1,23.9,152.8,25.8")
      this.svgPath.push("M166.7,46.9c-6.9,0-11.6-4.2-11.6-13.7c0-8.6,4.9-13.7,11.8-13.7s11.7,3.8,11.7,13.7C178.6,42.2,173.4,46.9,166.7,46.9 M166.8,24.5c-3.4,0-5.5,2.2-5.5,8.8s1.8,8.8,5.4,8.8s5.7-2.2,5.7-8.8S170.4,24.5,166.8,24.5")
      this.svgPath.push("M212,46.4V28.1c0-2.1-0.9-3.5-3.6-3.5c-1.9,0-3.4,0.6-4.9,1.7v20.1h-5.8V28.2c0-2.2-0.9-3.5-3.5-3.5c-1.8,0-3.4,0.6-5,1.7v20.1h-5.8V20.2c1.6-0.2,3.9-0.4,5.8-0.4v2.6h0.3c2.4-2.2,4.3-3,6.8-3s4.8,0.9,5.9,3.4c2.6-2.6,4.9-3.4,7.7-3.4c4.3,0,7.6,2.2,7.6,8.1v18.8H212L212,46.4z")

      // Abroad
      this.svgPath.push("M249.3,46.7l-2.8-9.3h-10.7l-2.8,9.3c-1.9,0-3.9-0.2-5.7-0.5c3.1-11,6.7-22.3,10.7-33.8h6.9c3.7,11.2,7.4,22.9,10.5,33.8C253.6,46.5,251.4,46.7,249.3,46.7 M241.8,21.3c-0.2-0.8-0.4-1.6-0.4-2.6H241c-0.1,1.1-0.1,1.8-0.4,2.6l-3.3,10.8h7.7L241.8,21.3z")
      this.svgPath.push("M271,46.9c-4,0-7.5-0.9-10.3-3V9.2c1.6-0.2,4-0.4,5.8-0.4v13.5h0.3c2.1-2.1,4.1-2.8,6.6-2.8c5.4,0,9.5,3.5,9.5,12.7S278.4,46.9,271,46.9 M271.6,24.6c-1.9,0-3.7,0.6-5.1,1.8v14.4c1.1,0.8,2.7,1.1,4.2,1.1c4.2,0,6-2.4,6-9.1C276.7,26.4,274.9,24.6,271.6,24.6")
      this.svgPath.push("M301.9,25.8c-0.6-0.1-1.5-0.2-2.4-0.2c-1.8,0-3.5,0.4-5.3,1.8v19.1h-5.8V20.2c1.6-0.2,3.9-0.4,5.8-0.4v3.4h0.3c2.6-3.4,4.3-3.7,6-3.7c0.5,0,1.3,0.1,1.9,0.3C302.4,21.8,302.2,23.9,301.9,25.8")
      this.svgPath.push("M317.1,46.9c-6.9,0-11.6-4.2-11.6-13.7c0-8.6,4.9-13.7,11.8-13.7S329,23.4,329,33.2C329,42.2,323.8,46.9,317.1,46.9 M317.2,24.5c-3.4,0-5.5,2.2-5.5,8.8s1.8,8.8,5.4,8.8s5.7-2.2,5.7-8.8S320.8,24.5,317.2,24.5")
      this.svgPath.push("M356.1,39.3c0,1.3,0.4,1.8,1.5,1.8h3V43c0,2.9-2,3.9-4.2,3.9c-2,0-4.1-0.7-5.2-3.5H351c-2.3,2.5-4.7,3.5-7.1,3.5c-6.1,0-9.6-4.2-9.6-13c0-9.5,4.6-14.5,11.5-14.5c1.5,0,3,0.3,4.5,0.7c0.9-0.4,2.3-0.6,3.6-0.6c0.8,0,1.6,0.1,2.2,0.2V39.3z M350.3,25.5c-1-0.6-2.5-1-3.9-1c-4,0-5.9,2.1-5.9,8.9s1.6,8.4,5.1,8.4c2,0,3.5-0.5,4.8-1.3L350.3,25.5L350.3,25.5z")
      this.svgPath.push("M386.9,46.9c-2,0-4.2-0.7-5.2-3.5h-0.2c-2.7,2.9-5.3,3.5-7.7,3.5c-6.1,0-9.3-4.5-9.3-13c0-10.3,4.8-14.5,11.5-14.5c1.5,0,3.4,0.3,4.9,0.8V9.2c1.6-0.2,4-0.4,5.8-0.4v30.6c0,1.3,0.4,1.8,1.5,1.8h3V43C391.1,46,389.1,46.9,386.9,46.9 M380.8,25.5c-1-0.6-2.8-1.1-4.3-1.1c-4,0-5.9,2.2-5.9,9s1.6,8.4,5.1,8.4c2,0,3.7-0.4,5.2-1.3V25.5L380.8,25.5z")

      // org
      this.svgPath.push("M433.7,46.9c-6.9,0-11.6-4.2-11.6-13.7c0-8.6,4.9-13.7,11.8-13.7s11.7,3.8,11.7,13.7C445.6,42.2,440.4,46.9,433.7,46.9 M433.8,24.5c-3.4,0-5.5,2.2-5.5,8.8s1.8,8.8,5.4,8.8s5.7-2.2,5.7-8.8S437.4,24.5,433.8,24.5")
      this.svgPath.push("M465,25.8c-0.6-0.1-1.5-0.2-2.4-0.2c-1.8,0-3.5,0.4-5.3,1.8v19.1h-5.8V20.2c1.6-0.2,4-0.4,5.8-0.4v3.4h0.3c2.6-3.4,4.4-3.7,6-3.7c0.5,0,1.3,0.1,1.9,0.3C465.6,21.8,465.3,23.9,465,25.8")
      this.svgPath.push("M478.7,57.3c-8,0-10.6-3.5-10.6-7.7c0-2.1,0.8-3.4,2.4-5.1c-0.6-1-1.2-2.3-1.6-3.5c0.4-1.5,1.5-4.7,2.9-6.4c-1.8-1.3-3-3.5-3-6.3c0-5.3,3.5-8.9,9.5-8.9c1,0,1.8,0.1,2.6,0.3c0-5,2.9-7.1,6.4-7.1c2.3,0,3.4,1.1,3.4,3.1v1.9h-2.8c-2,0-2.9,0.9-2.9,2.9v1.1c2,1.5,3,3.9,3,6.7c0,5.8-3.9,8.4-9.1,8.4c-1.3,0-2.6-0.2-3.5-0.5c-0.9,1.2-1.2,2.3-1.2,3c0,1.1,0.5,1.5,1.6,1.5h7.2c4.6,0,7.6,1.6,7.6,7.1S486.8,57.4,478.7,57.3 M482.7,46H474c-0.6,0.7-0.7,1.8-0.7,2.5c0,2.9,1.3,4,5.8,4c3.9,0,6.1-1.2,6.1-4.1C485.2,46.5,484.6,46,482.7,46 M478.6,23.6c-2.8,0-4.2,1.4-4.2,4.5s1.5,4.5,4.2,4.5s4.2-1.2,4.2-4.5S481.2,23.6,478.6,23.6")

      // white star
      this.svgDot.push("M415.6,33.1c0,5.3-4.3,9.5-9.7,9.5s-9.7-4.3-9.7-9.5s4.3-9.5,9.7-9.5S415.6,27.8,415.6,33.1")
      // red disk enclosing star
      this.svgDot.push("M405.8,23.1c5.6,0,10.1,4.4,10.1,10s-4.5,10-10.1,10s-10-4.4-10-10S400.2,23.1,405.8,23.1 M403.8,30.2h-6.6l5.4,4l-1.6,6.5l5.1-4l5,4l-1.6-6.5l5.4-4H408l-2.1-6.2L403.8,30.2L403.8,30.2z")
    },

    async createVotingGuide() {
      this.isActive = true

      function createNewPage(pageNumberColor) {
        page = pdfDoc.addPage(PageSizes.A4)
        currentYOnPage = bodyMarginTop

        pageNum++
        page.drawText(pageNum.toString(), { x: textRightMargin-guideTitleFontSize, y: pageHeight-guideTitleFontSize, font: boldFont, size: guideTitleFontSize, color: pageNumberColor })
      }

      function getXForCenter(text, font, fontSize, marginLeft, marginRight) {
        let wText = font.widthOfTextAtSize(text, fontSize)
        return (marginLeft+marginRight-wText)/2
      }

      function getDictFLJA(dictItem, dict) {
        /**
         * 2022-12-04 John Yee
         * This construction looks weird - like a recursive call; but, it's not.
         * The "getDictFLJ(dictItem, dict)" in the return statement is really
         * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
         * 
         * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
         */
        return getDictFLJ(dictItem, dict)
      }

      function getLinesFromLongline(longLine, font, fontSize, leftMargin, rightMargin) {
        if (typeof longLine==="undefined"){
          return []
        }
        if (!longLine.trim()){
          return []
        }
        
        const bodyWidth = rightMargin-leftMargin
        let words = longLine.trim().replace(/\s+/g, " ").split(" ")
        let lines = []
        let lineLength
        let nextWord = ''
        let oldLine = ''
        
        while (words.length>0) {
          nextWord = words.shift()
          lineLength = font.widthOfTextAtSize(oldLine + nextWord + ' ', fontSize)

          if (lineLength>bodyWidth) {
            lines.push(oldLine.trim())
            oldLine = nextWord + ' ' /* start a new oldLine with the nextWord as the first word */
          } else {
            oldLine += nextWord + ' '
          }
        }
        
        /* in case the last line is not blank, but ran out of words before the margin was exceeded */
        if (oldLine.trim()!=='') {
          lines.push(oldLine.trim())
        }
        
        return lines
      }

      function getLinesFromText(text, font, fontSize, leftMargin, rightMargin) {
        const breakToken = "<br>"
        let lines = []
        const textLines = text.trim().split(breakToken)

        for (let ii=0; ii<textLines.length; ii++) {
          let bt = getLinesFromLongline(textLines[ii], font, fontSize, leftMargin, rightMargin)
          lines = lines.concat(bt)
        }

        return lines
      }

      function drawTextBox(page, x, y, height, width, text, font, fontSize, backgroundColor, borderWidth, borderColor, borderOpacity, isXCentered, isYCentered) { 
        page.drawRectangle({
        x: x,
        y: y,
        width: width,
        height: height,
        color: backgroundColor,
        opacity: 1.0,
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderOpacity: borderOpacity
        })
    
        let textPadding = 5

        let textlines = []
        let ln = text.split("<br>")
        for (let nn=0; nn<ln.length; nn++) {
          let tx = getLinesFromText(ln[nn], font, fontSize, x, x+width-2*textPadding)
          textlines = textlines.concat(tx)
        }

        let y0
        let textHeight = font.heightAtSize(fontSize)*fontHeightScale
        if (isYCentered) {
          y0 = y+(height / 2) + (textlines.length*textHeight) /2 - (textHeight / 2)
          y0 = y+(height+(textlines.length-1)*textHeight)/2
        } else {
          y0 = y+height-fontSize-textPadding
        }

        for (let ii=0; ii<textlines.length; ii++) {
          let x0
          if (isXCentered) {
            const textWidth = font.widthOfTextAtSize(textlines[ii], fontSize)
            const textLeftMargin = (width - textWidth) / 2
            x0 = x+textLeftMargin
          } else {
            x0 = x+textPadding
          }
          page.drawText(textlines[ii], { x: x0, y: y0-ii*textHeight, size: fontSize })
        }
      }

      function makeCalendarOfElections( elections, COEName, colX, colWidth, rowYInitial, rowHeightInitial, dict ) {
        let rowY = rowYInitial
        let rowHeight = rowHeightInitial

        let rowWidth = 0
        for (let rr=0; rr<colWidth.length; rr++) {
          rowWidth += colWidth[rr]
        }

        // title 

        const xCenter = getXForCenter(COEName, boldFont, guideTitleFontSize, 0, pageWidth)
        page.drawText(COEName, { x: xCenter, y: rowY, font: boldFont, size: guideTitleFontSize, color: vfaRed })

        // table header

        rowY -= guideTitleFontSize + rowHeight

        drawTextBox(page, colX[0], rowY, rowHeight, colWidth[0], dict.P24, boldFont, boldTextFontSize, yellow, 1, black, 1.0, true, true)
        drawTextBox(page, colX[1], rowY, rowHeight, colWidth[1], dict.H19, boldFont, boldTextFontSize, yellow, 1, black, 1.0, true, true)
        drawTextBox(page, colX[2], rowY, rowHeight, colWidth[2], dict.P25, boldFont, boldTextFontSize, yellow, 1, black, 1.0, true, true)
        drawTextBox(page, colX[3], rowY, rowHeight, colWidth[3], dict.P26, boldFont, boldTextFontSize, yellow, 1, black, 1.0, true, true)
        drawTextBox(page, colX[4], rowY, rowHeight, colWidth[4], dict.P27, boldFont, boldTextFontSize, yellow, 1, black, 1.0, true, true)

        // election data

        if (elections.length<1) {
          rowY -= rowHeight
          page.drawText(dict.P30, { x: colX[0], y: rowY, font: boldFont, size: guideTitleFontSize })
        }

        for (let ii=0; ii<elections.length; ii++) {
          let rowColor = activityColors[elections[ii].activity].background
          let rowBorderColor = activityColors[elections[ii].activity].border
          let cellBorderOpacity = elections[ii].activity==="electionday" ? 0.0 : 1.0
          let cellBorderWidth = elections[ii].activity==="electionday" ? 2 : 1
          let month = parseInt(elections[ii].date.slice(5,7))
          let year = elections[ii].date.slice(0,4)

          if (month!==previousMonth) {
            // insert a row containing the name of the month
            rowY -= rowHeight
            let monthBox = suppressYearInMonthLine ? monthNames[month].toUpperCase() : year+" "+monthNames[month].toUpperCase()
            drawTextBox(page, colX[0], rowY, rowHeight, rowWidth, monthBox, boldFont, boldTextFontSize, white, cellBorderWidth, black, 1.0, false, true)
            previousMonth = parseInt(month)

            if (rowY<rowHeight) {
              createNewPage(vfaRed)
              rowY = pageHeight - tableElectionsTopMargin
            }
          }

          // rows of election data

          let yyyy = elections[ii].date.slice(0,4)
          let mm   = elections[ii].date.slice(5,7)
          let dd   = elections[ii].date.slice(8,10)
          let MDY  = monthAbbr[parseInt(mm)]+" "+dd+", "+yyyy
          
          if (elections[ii].activity!=="electionday") {
            let textPadding = 5 
            const linesfriendlyrule = getLinesFromText(elections[ii].friendlyrule, normalFont, normalTextFontSize, colX[3], colX[3]+colWidth[3]-2*textPadding)
            const linesnote         = getLinesFromText(elections[ii].note        , normalFont, normalTextFontSize, colX[4], colX[4]+colWidth[4]-2*textPadding)
            const nlines = linesfriendlyrule.length>linesnote.length ? linesfriendlyrule.length : linesnote.length

            const lineHeight = normalFont.heightAtSize(normalTextFontSize)*fontHeightScale
            const rowHeight = nlines * lineHeight + 3*textPadding

            rowY -= rowHeight

            drawTextBox(page, colX[0], rowY, rowHeight, colWidth[0], MDY, normalFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, false, false)
            drawTextBox(page, colX[1], rowY, rowHeight, colWidth[1], elections[ii].iso, normalFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, false, false)
            drawTextBox(page, colX[2], rowY, rowHeight, colWidth[2], elections[ii].friendlyactivity, normalFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, false, false)
            drawTextBox(page, colX[3], rowY, rowHeight, colWidth[3], elections[ii].friendlyrule, normalFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, false, false)
            drawTextBox(page, colX[4], rowY, rowHeight, colWidth[4], elections[ii].note, normalFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, false, false)
          } else {
            let electionDate  = monthNames[parseInt(mm)]+" "+dd

            let electionDayLabel = getDictFLJA( `election.${elections[ii].electiontype}`, dict)
            electionDayLabel = (electionDayLabel+" "+dict.D17).toUpperCase()

            let rowHeightN = rowHeight/2
            rowY -= rowHeightN

            /* draw a box around the entire election day row */
            drawTextBox(page, colX[0], rowY, rowHeightN, rowWidth, " ", boldFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, 1.0, true, true)

            drawTextBox(page, colX[0], rowY, rowHeightN, colWidth[0], electionDate, boldFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, true, true)
            drawTextBox(page, colX[1], rowY, rowHeightN, colWidth[1], elections[ii].iso, boldFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, true, true)
            drawTextBox(page, colX[2], rowY, rowHeightN, colWidth[2]+colWidth[3]+colWidth[4], electionDayLabel, boldFont, normalTextFontSize, rowColor, cellBorderWidth, rowBorderColor, cellBorderOpacity, false, true)
          }

          if (rowY<rowHeight) {
            createNewPage(vfaRed)
            rowY = pageHeight - tableElectionsTopMargin
          }
        }
      }

      /* convert assets/css/style.scss hex values to rbg */

      const vfaBlue = rgb(0.0000, 0.0353, 0.6000) /* $vfa = #000999 */
      const vfaRed  = rgb(0.7490, 0.1804, 0.1020) /* $red = #BF2E1A */
      const white   = rgb(1.0000, 1.0000, 1.0000)
      const green   = rgb(0.0000, 1.0000, 0.0000)
      const blue    = rgb(0.0000, 0.0000, 1.0000)
      const blueish	= rgb(0.8700, 0.9200, 0.9700) // register: a bit dark <==
      const yellow  = rgb(1.0000, 1.0000, 0.0000)
      const black   = rgb(0.0000, 0.0000, 0.0000)
      const beige   = rgb(0.9900, 0.9000, 0.8000)
      const mauve   = rgb(0.8700, 0.8000, 0.9200) // Ballot Return: a bit dark <==

      const textLeftMargin = 50
      // const textRightMargin = textLeftMargin /** may be an inconsistency because textRightMargin is declared further down */

      const bannerFontSize = 20
      const marginIconFontSize = 40
      const normalTextFontSize = 10
      const boldTextFontSize = 10
      const pageTitleFontSize = 30
      const	guideTitleFontSize = 30
      const stepTitleFontSize = 20
      const fontHeightScale = 1.2 // empirical value that gives more space between lines of text

      const logoScale = 0.5

      const guideTitle = this.dict.P45
      const byLine = this.dict.P34
      const author = this.dict.P35
      const onDate = this.dict.P36
      const disclaimer = this.dict.P37
      const disclaimerBodyRaw = this.dict.P38
      const noLiability = this.dict.P39
      const noLiabilityBodyRaw = this.dict.P40
      const endGuide = this.dict.P46

      const pdfDoc = await PDFDocument.create()

      const normalFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

      /*
        2023-01-14 John Yee
        The heightAtSize method does not return the displayed height of an upper case letter
        i.e. height=top-bottom of the displayed character.
        By trial ane error it seems that for boldFont at size 40 the displayed size is about 0.88
        of the size returned by heightAtSize.
        So I adjust the value returned by heightAtSize by the marginIconYScale factor.
      */
      const marginIconYScale = 0.88

      const marginIconHeight = boldFont.heightAtSize(marginIconFontSize)*marginIconYScale
      const marginIconWidth  = boldFont.widthOfTextAtSize('O', marginIconFontSize) // 'O' (oh) is merely a wide letter for calibration
      const marginIconX = textLeftMargin - marginIconWidth

      const stepTitleHeight = boldFont.heightAtSize(stepTitleFontSize)
  	  const tableEntryHeight = normalFont.heightAtSize(normalTextFontSize)

      let page = pdfDoc.addPage(PageSizes.A4)

      /*
        document coordinates in points not pixels
        (0,0) is lower left corner of the page
        (width,height) is upper right corner of the page
        A4 dimensions [595.28, 841.89]
        Letter dimensions [612,792]
      */
      const { width, height } = page.getSize()
      const pageWidth  = width
      const pageHeight = height

      const bodyMarginBottom = 3*guideTitleFontSize
      const bodyMarginTop = pageHeight-bodyMarginBottom

      const textRightMargin = pageWidth - textLeftMargin

      let pageNum=0
      let currentYOnPage /* not the line number because lines may not be equal heights; remember 0 === bottom edge */

      /* for Table of Contents */

      let tableYOnPage
      let tableColumnTop
      let tableColumnMarginLeft = textLeftMargin
      const tableColumnWidth = (textRightMargin-textLeftMargin)/2

      /* for Calendar of Elections */

      const tableElectionsLeftMargin = 10
      const tableElectionsTopMargin = 2*guideTitleFontSize
      const tableElectionsBottomMargin = 10
      const tableElectionsRightMargin = pageWidth - tableElectionsLeftMargin

      const activityColors = { "electionday": {background: green, border: black}, "registration": {background: blueish, border: black}, "ballotrequest": {background: beige, border: black}, "ballotreturn": {background: mauve, border: black} }
      const monthNames = [
            this.dict.L16,
            this.dict.L04,
            this.dict.L05,
            this.dict.L06,
            this.dict.L07,
            this.dict.L08,
            this.dict.L09,
            this.dict.L10,
            this.dict.L11,
            this.dict.L12,
            this.dict.L13,
            this.dict.L14,
            this.dict.L15
          ]
      const monthAbbr = monthNames.map(m=>m.slice(0,3))

      const col1X = tableElectionsLeftMargin
      const col1Width = 80
      
      const col2X = col1X + col1Width
      const col2Width = 60
      
      const col3X = col2X + col2Width
      const col3Width = 75
      
      const col4X = col3X + col3Width
      const col4Width = 150
      
      const col5X = col4X + col4Width
      const col5Width = tableElectionsRightMargin - col5X
      
      const rowWidth = col1Width + col2Width + col3Width + col4Width + col5Width

      const colX = [ col1X, col2X, col3X, col4X, col5X ]
      const colWidth = [ col1Width, col2Width, col3Width, col4Width, col5Width ]
      
      let rowHeight = 50
      let rowY
      let month = 0
      let previousMonth = 0
      let suppressYearInMonthLine = true
      if (this.statesElections.length>0) {
        suppressYearInMonthLine = this.statesElections[this.statesElections.length-1].date.slice(0,4)===this.statesElections[0].date.slice(0,4)
      }

      /* for Table of LEOs */

      let leoTableColumn
      let leoTableColumnMarginLeft
      const leoTableColumnWidth = (textRightMargin-textLeftMargin)/2

      let statePageLink = []
      let tablePageLink = []

      /* Cover Page */
      this.setupLogoSVG()

      pageNum++
      currentYOnPage = pageHeight

      /* Index for internal links */
      let linkIndex = 0

      /** **********************
       *  Build the PDF document
       *  ********************** */

      /* Logo */

      // rectangle preceding the VFA logo
      const bannerY = pageHeight-2*bannerFontSize
      page.drawRectangle({
        x: 0,
        y: bannerY,
        width: textLeftMargin-2,
        height: bannerFontSize,
        color: vfaBlue,
        opacity: 1.0,
      })

      // the actual Logo 
      page.moveTo(textLeftMargin, bannerY+bannerFontSize*1.25) // assume visible font height = 0.80 of the font size; move the bottom of the viewbox up
      for (let ii=0; ii<this.svgPath.length; ii++) {
        page.drawSvgPath(this.svgPath[ii], {borderColor: vfaBlue, color: vfaBlue, scale: logoScale})
      }
      page.drawSvgPath(this.svgDot[0], {borderColor: vfaRed, color: white, scale: logoScale})
      page.drawSvgPath(this.svgDot[1], {borderColor: vfaRed, color: vfaRed, scale: logoScale})

      /* "Front Matter" */

      const xTitle = getXForCenter(guideTitle, boldFont, guideTitleFontSize, 0, pageWidth)
      currentYOnPage = bannerY-5*guideTitleFontSize
      page.drawText(guideTitle, { x: xTitle, y: currentYOnPage, font: boldFont, size: guideTitleFontSize, color: vfaBlue })

      const xByLine = getXForCenter(byLine, normalFont, normalTextFontSize, 0, pageWidth)
      currentYOnPage -= guideTitleFontSize
      page.drawText(byLine, { x: xByLine, y: currentYOnPage, size: normalTextFontSize })	  
      
      const xAuthor = getXForCenter(author, boldFont, boldTextFontSize, 0, pageWidth)
      currentYOnPage -= 1.5*boldTextFontSize
      page.drawText(author, { x: xAuthor, y: currentYOnPage, font: boldFont, size: boldTextFontSize })	  

      const xOnDate = getXForCenter(onDate, normalFont, normalTextFontSize, 0, pageWidth)
      currentYOnPage -= guideTitleFontSize
      page.drawText(onDate, { x: xOnDate, y: currentYOnPage, size: normalTextFontSize })	  
      
      const dateString = new Date().toString()
      const xDateString = getXForCenter(dateString, boldFont, boldTextFontSize, 0, pageWidth)
      currentYOnPage -= 1.5*boldTextFontSize
      page.drawText(dateString, { x: xDateString, y: currentYOnPage, font: boldFont, size: boldTextFontSize })	  

      const xDisclaimer = getXForCenter(disclaimer, boldFont, boldTextFontSize, 0, pageWidth)
      currentYOnPage -= guideTitleFontSize
      page.drawText(disclaimer, { x: xDisclaimer, y: currentYOnPage, font: boldFont, size: boldTextFontSize })	  
      
      const disclaimerBody = getLinesFromLongline(disclaimerBodyRaw, normalFont, normalTextFontSize, textLeftMargin, pageWidth-textLeftMargin)
      currentYOnPage -= normalTextFontSize
      for (let ii=0; ii<disclaimerBody.length; ii++) {
        let xLine = getXForCenter(disclaimerBody[ii], normalFont, normalTextFontSize, textLeftMargin, pageWidth-textLeftMargin)
        currentYOnPage -= normalTextFontSize 
        page.drawText(disclaimerBody[ii], { x: xLine, y: currentYOnPage, font: normalFont, size: normalTextFontSize })	  
      }

      const xNoLiability = getXForCenter(noLiability, boldFont, boldTextFontSize, 0, pageWidth)
      currentYOnPage -= guideTitleFontSize
      page.drawText(noLiability, { x: xNoLiability, y: currentYOnPage, font: boldFont, size: boldTextFontSize })	  
      
      const noLiabilityBody = getLinesFromLongline(noLiabilityBodyRaw, normalFont, normalTextFontSize, textLeftMargin, pageWidth-textLeftMargin)
      currentYOnPage -= normalTextFontSize
      for (let ii=0; ii<noLiabilityBody.length; ii++) {
        let xLine = getXForCenter(noLiabilityBody[ii], normalFont, normalTextFontSize, textLeftMargin, pageWidth-textLeftMargin)
        currentYOnPage -= normalTextFontSize 
        page.drawText(noLiabilityBody[ii], { x: xLine, y: currentYOnPage, font: normalFont, size: normalTextFontSize })	  
      }

      /* Calendar of Elections - All States */

      createNewPage(vfaRed)

      const COEName = this.dict.P28

      const rowYInitialA = pageHeight - tableElectionsTopMargin - guideTitleFontSize
      makeCalendarOfElections( this.statesElections, COEName, colX, colWidth, rowYInitialA, rowHeight, this.dict )

      /* End-of-Guide */

      page = pdfDoc.addPage(PageSizes.A4)
      pageNum++
      
      const xEndGuide = getXForCenter(endGuide, boldFont, guideTitleFontSize, 0, pageWidth)
      currentYOnPage = pageHeight/2
      page.drawText(endGuide, { x: xEndGuide, y: currentYOnPage, boldFont, size: guideTitleFontSize })  

      /* document metadata */

      pdfDoc.setTitle('State Elections Guide')
      pdfDoc.setAuthor('VoteFromAbroad')
      pdfDoc.setSubject('State Elections Guide')
      pdfDoc.setKeywords(['vote'])
      pdfDoc.setProducer('VoteFromAbroad')
      pdfDoc.setCreator('VoteFromAbroad (https://votefromabroad.org)')
      pdfDoc.setCreationDate(new Date())
      pdfDoc.setModificationDate(new Date())

      /* "Print" the document */

      const pdfBytes = await pdfDoc.save()
      this.isActive = false
      download(pdfBytes, "State-Elections-Guide.pdf", "application/pdf")
    },
    async getLeo(state) {
      let fvapLeos = (await axios.get(`/leos/${state}-leos.json`)).data
      let updatedLeos = updateFvapLeosWithButter(fvapLeos, this.butterLeos)
      return updatedLeos.filter(leo => leo.fpcaOffice).sort((a,b)=>{return (a.n<b.n)?-1:1})
    },
  },
}
